import { template as template_12a18d74725d4c58a3ed2c40722b3ad7 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_12a18d74725d4c58a3ed2c40722b3ad7(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
