import { template as template_24e1d6e61940402f92423f9852cfb713 } from "@ember/template-compiler";
const SidebarSectionMessage = template_24e1d6e61940402f92423f9852cfb713(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
