import { template as template_6290b840ff50443abd9843c72f61e879 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6290b840ff50443abd9843c72f61e879(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
